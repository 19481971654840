import { ResultModalContentProps } from '@/components/ResultModal/ResultModal';
import { CreateLeadResultType } from '@/entities/modal';

export const LEAD_RESULT_CONTENT_PROPS_CONFIG: Record<CreateLeadResultType, ResultModalContentProps> = {
  [CreateLeadResultType.success]: {
    title: 'Заявка принята',
    description: 'Клиент уникален и закреплён.',
    buttonText: 'Отправить еще',
    iconName: 'IconBusiness',
    iconHeight: 300,
  },
  [CreateLeadResultType.refused]: {
    title: 'Заявка отклонена',
    description: 'Клиент неуникален и не может быть закреплён.',
    buttonText: 'Отправить другую заявку',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
  [CreateLeadResultType.refusedWithInfo]: {
    title: 'Заявка отклонена',
    description: 'К сожалению, клиент неуникален и не может быть закреплён.',
    buttonText: 'Отправить другую заявку',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
  [CreateLeadResultType.refusedWithInfoList]: {
    title: 'Заявка отклонена',
    description: 'Клиент неуникален и не может быть закреплён.',
    buttonText: '',
    iconName: 'IconRefusal',
    iconHeight: 195,
  },
};
