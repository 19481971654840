import cn from 'classnames';
import * as React from 'react';

import BaseClickable, { ClickedProps, ValueProp } from '@/components/BaseClickable';
import Typo, { TypoElement, TypoView } from '@/components/Typo';

import Loader from '../Loader';

import { textConfig } from './config';
import { ButtonSize, ButtonType } from './types';

import s from './Button.module.scss';

export type ButtonProps<V extends ValueProp> = {
  children?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: string;
  full?: boolean;
  size?: ButtonSize;
  view?: ButtonType;
  textView?: TypoView;
  isLoading?: boolean;
  disabled?: boolean;
  // Кнопка продолжает быть активной, но визуально выглядит неактивной
  visuallyDisabled?: boolean;
} & ClickedProps<V>;

const LOADER_SIZE = {
  [ButtonSize.large]: 24,
  [ButtonSize.medium]: 24,
  [ButtonSize.small]: 20,
  [ButtonSize.xs]: 16,
  [ButtonSize.custom]: 24,
};

const BaseButton = <V extends ValueProp>(
  {
    view = ButtonType.primary,
    size = ButtonSize.medium,
    textView: outterTextView,
    children,
    before,
    after,
    full,
    isLoading,
    disabled,
    visuallyDisabled,
    className,
    ...props
  }: ButtonProps<V>,
  ref: React.ForwardedRef<HTMLElement>,
): React.ReactElement => {
  const textView = outterTextView ?? textConfig[size];
  const loaderSize = LOADER_SIZE[size];

  return (
    <BaseClickable
      className={cn(
        s.button,
        s[`button_view-${view}`],
        s[`button_size-${size}`],
        isLoading && s.button_loading,
        disabled && s.button_disabled,
        visuallyDisabled && s['button_visually-disabled'],
        full && s.button_full,
        className,
        before && s['button_with-before'],
      )}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {isLoading ? (
        <Loader loaderSize={loaderSize} withCaption={false} />
      ) : (
        <>
          {before}
          {children && (
            <Typo className={s['button__text']} element={TypoElement.span} view={textView}>
              {children}
            </Typo>
          )}
          {after}
        </>
      )}
    </BaseClickable>
  );
};

export default React.forwardRef(BaseButton) as typeof BaseButton;
