import * as React from 'react';

import { useStrictContext } from '@kts-front/hooks';

import LeadCreationModalStore from './LeadCreationModalStore';

type LeadCreationModalStoreContextValue = LeadCreationModalStore;

export const LeadCreationModalStoreContext = React.createContext<LeadCreationModalStoreContextValue | null>(null);

export const useLeadCreationModalStore = () => {
  const context = useStrictContext({
    context: LeadCreationModalStoreContext,
    message: 'LeadCreationModalStoreContext was not provided',
  });

  return context;
};
