import { computed, makeObservable } from 'mobx';

import { PlaceTypeEnum } from '@/entities/dictionaries';
import { IDictionariesStore } from '@/stores/global/DictionariesStore';
import { IRootStore } from '@/stores/global/RootStore';
import { LocalStore } from '@/stores/local/LocalStore';
import { validateIf, validateINN, validateMaxLength, validateNotEmpty, validateNumber } from '@/utils/validators';

import { CheckboxGridModel } from '../CheckboxGridModel';
import { InputModel } from '../InputModel';

import { FixationFormFields } from './config';

export type FixationFormDefaultValues = { inn?: string | null };

type Params = {
  rootStore: IRootStore;
  defaultValues?: FixationFormDefaultValues;
};

export class FixationFormModel extends LocalStore {
  readonly [FixationFormFields.project] = new CheckboxGridModel<number | null>({
    name: 'project',
    caption: 'Проект',
    required: true,
    initialValue: null,
    validators: [validateNotEmpty('Необходимо выбрать проект')],
  });

  readonly [FixationFormFields.placeType] = new CheckboxGridModel<PlaceTypeEnum | null>({
    name: 'place_type',
    caption: 'Вид помещения',
    required: true,
    initialValue: null,
    validators: [validateNotEmpty('Необходимо выбрать вид помещения')],
  });

  readonly [FixationFormFields.clientComment] = new InputModel<string, HTMLTextAreaElement>({
    name: 'client_comment',
    caption: 'Оставьте свой комментарий',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty(), validateMaxLength(100, 'Максимальное количество символов - 100')],
  });

  readonly [FixationFormFields.source] = new CheckboxGridModel<string | null>({
    name: 'source',
    caption: 'Тип источника',
    initialValue: null,
  });

  readonly [FixationFormFields.inn]: InputModel;

  private _rootStore: IRootStore;

  constructor({ rootStore, defaultValues }: Params) {
    super();

    this._rootStore = rootStore;

    this[FixationFormFields.inn] = new InputModel({
      name: 'inn',
      caption: 'ИНН',
      disabled: Boolean(defaultValues?.inn),
      initialValue: defaultValues?.inn ?? '',
      validatorsOnChange: [validateNumber()],
      validators: [validateIf<string>(() => this.isCommercialPlaceType && Boolean(this.inn.value), validateINN())],
    });

    this._setDefaultValues();

    makeObservable<this, '_fieldsToValidate'>(this, {
      isError: computed,
      expectedPlaceTypes: computed,
      _fieldsToValidate: computed,
    });
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get isError(): boolean {
    let isError = false;

    this._fieldsToValidate.forEach((field) => {
      if (this[field].isError) {
        isError = true;
      }
    });

    return isError;
  }

  get expectedPlaceTypes(): Set<PlaceTypeEnum> | undefined {
    const projectId = this[FixationFormFields.project].value;

    if (!projectId) {
      return;
    }

    return this.dictionaries.projects.getEntity(projectId)?.placeTypes;
  }

  get isCommercialPlaceType(): boolean {
    return this[FixationFormFields.placeType].value === PlaceTypeEnum.commercial;
  }

  changeProject = (value: number): void => {
    this[FixationFormFields.project].onChange(value);

    const placeTypes = this.expectedPlaceTypes;

    if (placeTypes) {
      const placeType = placeTypes.values().next().value;

      if (placeType) {
        this[FixationFormFields.placeType].change(placeType);
      }
    }
  };

  scrollToErrorField = () => {
    const errorField = this._fieldsToValidate.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  validate = (): boolean => {
    this._fieldsToValidate.forEach((field) => {
      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();
    }

    return this.isError;
  };

  toJson = () => {
    return {
      project: this[FixationFormFields.project].value,
      place_type: this[FixationFormFields.placeType].value,
      comment: this[FixationFormFields.clientComment].value,
      source_type: this[FixationFormFields.source].value,
      inn: this.isCommercialPlaceType ? this[FixationFormFields.inn].value || null : null,
    };
  };

  reset = (): void => {
    Object.values(FixationFormFields).forEach((field) => {
      this[field].reset();
    });

    this._setDefaultValues();
  };

  destroy(): void {
    super.destroy();
    this.reset();
  }

  changePlaceType = (slug: PlaceTypeEnum): void => {
    this[FixationFormFields.placeType].change(slug);
    this[FixationFormFields.inn].resetError();
  };

  private get _fieldsToValidate(): FixationFormFields[] {
    return Object.values(FixationFormFields);
  }

  private _setDefaultValues = (): void => {
    const firstProject = this.dictionaries.projects.items[0];

    if (firstProject) {
      this[FixationFormFields.project].change(firstProject.id);

      const placeType = firstProject.placeTypes.values().next().value;

      if (placeType) {
        this[FixationFormFields.placeType].change(placeType);
      }
    }
  };
}
