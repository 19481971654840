import { observer } from 'mobx-react-lite';
import * as React from 'react';

import TextareaField from '@/components/form/TextareaField';
import { TextareaFieldProps } from '@/components/form/TextareaField/TextareaField';
import { TypoElement, TypoView } from '@/components/Typo';
import Typo from '@/components/Typo/Typo';
import { useUiStore } from '@/stores/global/RootStore';
import { withRequiredSymbol } from '@/utils/withRequiredSymbol';

import s from './Comment.module.scss';

type Props = {
  className?: string;
  commentProps: TextareaFieldProps;
};

const Comment: React.FC<Props> = ({ className, commentProps }) => {
  const { isPhone } = useUiStore();

  return (
    <div className={className}>
      <Typo
        className={s.comment__title}
        view={isPhone ? TypoView.h4 : TypoView.h3}
        element={isPhone ? TypoElement.h4 : TypoElement.h3}
      >
        {withRequiredSymbol('Комментарий', true)}
      </Typo>
      <TextareaField {...commentProps} rows={5} />
    </div>
  );
};

export default observer(Comment);
