import { action, makeObservable, observable } from 'mobx';

import { IUser, UserServer } from '@/entities/user';
import { getFullName } from '@/utils/getFullName';

import { AgencyModel } from './agency/AgencyModel';

export class UserModel implements IUser {
  readonly id;
  readonly agency: AgencyModel;
  readonly phone;
  readonly email;
  readonly externalId;
  readonly lastName;
  readonly firstName;
  readonly role;
  readonly isBlocked: boolean;
  private _unreadNotificationsCount: number;

  constructor({
    id,
    agency,
    phone,
    email,
    externalId,
    firstName,
    lastName,
    role,
    isBlocked,
    unreadNotificationsCount,
  }: Omit<IUser, 'agency'> & {
    agency: AgencyModel;
  }) {
    this.id = id;
    this.agency = agency;
    this.phone = phone;
    this.email = email;
    this.externalId = externalId;
    this.lastName = lastName;
    this.firstName = firstName;
    this.role = role;
    this.isBlocked = isBlocked;
    this._unreadNotificationsCount = unreadNotificationsCount;

    makeObservable<this, '_unreadNotificationsCount'>(this, {
      _unreadNotificationsCount: observable,

      setUnreadNotificationsCount: action,
    });
  }

  get fullName(): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName, reversed: false, short: true });
  }

  get unreadNotificationsCount(): number {
    return this._unreadNotificationsCount;
  }

  setUnreadNotificationsCount(value: number): void {
    this._unreadNotificationsCount = value;
  }

  static fromJson(raw: UserServer): UserModel {
    const agency = AgencyModel.fromJson(raw.agency);

    return new UserModel({
      id: raw.id,
      agency,
      phone: raw.phone,
      email: raw.email,
      externalId: raw.external_id,
      lastName: raw.last_name,
      firstName: raw.first_name,
      role: raw.role,
      isBlocked: raw.is_blocked ?? false,
      unreadNotificationsCount: raw.unread_notifications_count,
    });
  }
}
