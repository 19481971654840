import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import { useScroll } from '@/utils/useScroll';

import s from './ScrollUpBottom.module.scss';

type Props = {
  containerRef: React.RefObject<HTMLDivElement | null>;
  scrollUpButtonCls?: string;
};

const ScrollUpBottom: React.FC<Props> = ({ containerRef, scrollUpButtonCls }) => {
  const [showButton, setShowButton] = React.useState(false);

  const handleScroll = React.useCallback((ref: HTMLDivElement) => {
    const limit = 0.35 * ref.clientHeight;

    if (ref.scrollTop < limit) {
      setShowButton(false);

      return;
    }

    setShowButton(true);
  }, []);
  useScroll(containerRef, handleScroll);

  const handleClick = React.useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Button
      view={ButtonType.secondary}
      after={<Icon iconName="IconArrowUp" />}
      className={cn(s.button, scrollUpButtonCls, { [s.button_visible]: showButton })}
      onClick={handleClick}
    />
  );
};

export default ScrollUpBottom;
