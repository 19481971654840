import { useMemo } from 'react';

import { InfoItem } from '@/components/InfoGrid';
import { ILead } from '@/entities/lead';
import { PhoneNumber } from '@/models/PhoneNumber';
import { Nullable } from '@/types/values';

export const useFormattedInfo = (lead?: ILead | null) => {
  const clientInfo: Nullable<InfoItem[][]> = useMemo(() => {
    if (!lead) {
      return null;
    }

    return [
      [
        {
          label: 'ФИО',
          value: lead.fullName,
        },
        {
          label: 'Телефон',
          value: PhoneNumber.format(lead.phone),
        },
        {
          label: 'Email',
          value: lead.email,
        },
      ],
    ];
  }, [lead]);

  const subAgentInfo: Nullable<InfoItem[][]> = useMemo(() => {
    const subAgent = lead?.subAgent;

    if (!subAgent) {
      return null;
    }

    return [
      [
        {
          label: 'ФИО',
          value: subAgent.name,
        },
        {
          label: 'Телефон',
          value: subAgent.phone ? PhoneNumber.format(subAgent.phone) : '',
        },
      ],
    ];
  }, [lead?.subAgent]);

  return {
    clientInfo,
    subAgentInfo,
  };
};
