import { observer } from 'mobx-react-lite';
import * as React from 'react';

import TextButton from '@/components/TextButton';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import s from './CardField.module.scss';

type Props = {
  title: string;
  value: React.ReactNode;
  valueView?: TypoView;
  captionView?: TypoView;
  valueMaxLines?: number;
  valueClassName?: string;
  valueWithExpand?: boolean;
};
const EMPTY_CONTENT = '—';

const CardField: React.FC<Props> = ({
  title,
  value,
  valueView = TypoView.text,
  captionView = TypoView.caption,
  valueMaxLines,
  valueClassName,
  valueWithExpand = false,
}: Props) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpandToggle = (): void => {
    setExpanded(!expanded);
  };

  const { isPhone } = useRootStore().uiStore;

  return (
    <div className={s.card}>
      <Typo view={captionView} color="text-grey" weight={TypoWeight.medium}>
        {title}
      </Typo>

      <Typo maxLines={expanded ? undefined : valueMaxLines} view={valueView} className={valueClassName}>
        {value || EMPTY_CONTENT}
      </Typo>

      {valueWithExpand && (
        <TextButton underlined={isPhone} textView={valueView} onClick={handleExpandToggle} className={s.card__btn}>
          {expanded ? 'Свернуть' : 'Читать'}
        </TextButton>
      )}
    </div>
  );
};

export default observer(CardField);
