export enum TypoView {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  text = 'text',
  textMedium = 'text-medium',
  textSmall = 'text-small',
  buttonText = 'button-text',
  buttonLarge = 'button-large',
  buttonMedium = 'button-medium',
  buttonSmall = 'button-small',
  caption = 'caption',
}

export enum TypoWeight {
  regular = 'regular',
  medium = 'medium',
}

export enum TypoElement {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  div = 'div',
  span = 'span',
  label = 'label',
  p = 'p',
  ol = 'ol',
  ul = 'ul',
  li = 'li',
  a = 'a',
}

export enum TypoAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}
