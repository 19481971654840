import { isValidPhoneNumber } from 'react-phone-number-input';

import { Validator, ValidatorResult } from '@/types/validator';
import { DateRange } from '@/types/values';

const ERROR_REQUIRED_FIELD = 'Обязательное поле';
const ERROR_NUMBER = 'В поле должно быть число';
const ERROR_EMAIL = 'Введите корректный email';
const ERROR_PHONE_NUMBER = 'Введите корректный номер телефона';
const ERROR_MAX_LENGTH = 'Превышена максимальная длина';
const ERROR_MIN_LENGTH = 'Слишком короткое значение';
const ERROR_INN = 'Некорректное значение ИНН';
const ERROR_KPP = 'Некорректное значение КПП';
const ERROR_OGRN = 'Некорректное значение ОГРН';
const ERROR_BIC = 'Некорректное значение БИК';
const ERROR_PAYMENT_ACCOUNT = 'Некорректное значение расчетного счета';
const ERROR_CORRESPONDENT_ACCOUNT = 'Некорректное значение корреспондентского счета';
const ERROR_DATE_RANGE = 'Неверный диапазон дат';

export const validateNotEmpty =
  <T>(error: string = ERROR_REQUIRED_FIELD): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || !String(value).trim().length ? error : null;

export const validateMaxLength =
  <T>(maxLength: number, error: string = ERROR_MAX_LENGTH): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || String(value).trim().length > maxLength ? error : null;

export const validateMinLength =
  <T>(minLength: number, error: string = ERROR_MIN_LENGTH): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    value === undefined || value === null || String(value).trim().length < minLength ? error : null;

export const validateNumber =
  <T>(error: string = ERROR_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult =>
    /[^0-9]/.test(String(v)) ? error : null;

export const validateINN =
  <T>(error: string = ERROR_INN): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    /^(\d{10}|\d{12})$/.test(String(value)) ? null : error;

export const validateKPP =
  <T>(error: string = ERROR_KPP): Validator<T> =>
  (value?: T | null): ValidatorResult =>
    /^\d{9}$/.test(String(value)) ? null : error;

export const validateOGRN =
  (error: string = ERROR_OGRN): Validator<string> =>
  (ogrn): ValidatorResult => {
    // Проверка, что строка состоит только из цифр
    if (!/^\d{13}$/.test(ogrn)) {
      return error;
    }

    // Логика для ОГРН (13 цифр)
    const checkSum = parseInt(ogrn.slice(0, 12)) % 11;
    const controlDigit = checkSum === 10 ? 0 : checkSum;

    return parseInt(ogrn[12]) === controlDigit ? null : error;
  };

export const validateOGRNIP =
  (error: string = ERROR_OGRN): Validator<string> =>
  (ogrn): ValidatorResult => {
    // Проверка, что строка состоит только из цифр
    if (!/^\d{15}$/.test(ogrn)) {
      return error;
    }

    // Логика для ОГРНИП (15 цифр)
    const checkSum = parseInt(ogrn.slice(0, 14)) % 13;
    const controlDigit = checkSum === 10 ? 0 : checkSum;

    return parseInt(ogrn[14]) === controlDigit ? null : error;
  };

export const validateBic =
  (error: string = ERROR_BIC): Validator<string> =>
  (bic): ValidatorResult => {
    // БИК должен состоять из 9 цифр
    if (!/^\d{9}$/.test(bic)) {
      return error;
    }

    // Первые две цифры БИК должны быть '04' (код страны для России)
    if (bic.slice(0, 2) !== '04') {
      return error;
    }

    return null;
  };

export const validatePaymentAccount =
  (error: string = ERROR_PAYMENT_ACCOUNT): Validator<string> =>
  (paymentAccount): ValidatorResult => {
    // Расчетный счет должен состоять из 20 цифр
    if (!/^\d{20}$/.test(paymentAccount)) {
      return error;
    }

    return null;
  };

export const validateCorrespondentAccount =
  (error: string = ERROR_CORRESPONDENT_ACCOUNT): Validator<string> =>
  (correspondentAccount): ValidatorResult => {
    // Корреспондентский счет должен состоять из 20 цифр
    if (!/^\d{20}$/.test(correspondentAccount)) {
      return error;
    }

    return null;
  };

export const validateEmail =
  (error: string = ERROR_EMAIL): Validator<string> =>
  (v) =>
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(String(v)) ? null : error;

export const validateNotEmptyEmail =
  (error: string = ERROR_EMAIL): Validator<string> =>
  (v) =>
    v.length === 0 ? null : validateEmail(error)(v);

export const validatePhoneNumber =
  <T>(error: string = ERROR_PHONE_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult => {
    return typeof v === 'string' && isValidPhoneNumber(v) ? null : error;
  };

export const validateNotEmptyPhoneNumber =
  <T>(error: string = ERROR_PHONE_NUMBER): Validator<T> =>
  (v?: T | null): ValidatorResult => {
    return v === null || (typeof v === 'string' && (v.length === 0 || isValidPhoneNumber(v))) ? null : error;
  };

export const validateDateRange =
  (error: string = ERROR_DATE_RANGE): Validator<DateRange> =>
  ([from, to]) => {
    if (from && to && from > to) {
      return error;
    }

    return null;
  };

export const validateIf = <T>(condition: () => boolean, validator: Validator<T>): Validator<T> => {
  return (value: T): ValidatorResult => {
    if (condition()) {
      return validator(value);
    }

    return null;
  };
};
