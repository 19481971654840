import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import CheckboxGrid, { CheckboxGridOption } from '@/components/form/CheckboxGrid';
import { PlaceTypeEnum } from '@/entities/dictionaries';
import { FixationFormModel } from '@/models/FixationFormModel';

import s from './ProjectAndPlaceType.module.scss';

type Props = {
  formModel: FixationFormModel;
  className?: string;
};

const ProjectAndPlaceType: React.FC<Props> = ({ formModel, className }) => {
  const { project, placeType, dictionaries, changeProject, expectedPlaceTypes, changePlaceType } = formModel;
  const { projects } = dictionaries;

  const options = React.useMemo((): CheckboxGridOption<PlaceTypeEnum>[] => {
    return dictionaries.placeTypes.items.map((placeType) => ({
      id: placeType.slug,
      label: placeType.label,
    }));
  }, [dictionaries.placeTypes.items]);

  return (
    <div className={cn(s.wrapper, className)}>
      <CheckboxGrid
        {...project.props}
        columns={projects.length > 2 ? 3 : 2}
        options={projects.items}
        onChange={changeProject}
      />
      <CheckboxGrid
        {...placeType.props}
        options={options}
        expectedValues={expectedPlaceTypes}
        onChange={changePlaceType}
      />
    </div>
  );
};

export default observer(ProjectAndPlaceType);
