import * as React from 'react';

import DialogStub, { DialogContentProps } from '../DialogStub';

type Props = React.PropsWithChildren<{
  isError: boolean;
  isEmpty: boolean;
  hasSelectedFiltersOrSearch?: boolean;
  refetch: () => void;
  resetFilters?: () => void;
  emptyState?: DialogContentProps;
}>;

const StubWrapper: React.FC<Props> = ({
  isEmpty,
  isError,
  hasSelectedFiltersOrSearch,
  refetch,
  resetFilters,
  emptyState,
  children,
}: Props) => {
  if (isError) {
    return (
      <DialogStub
        text="Что-то пошло не так. Пожалуйста, попробуйте позже"
        buttonText="Попробовать еще раз"
        onClick={refetch}
      />
    );
  }

  if (isEmpty && hasSelectedFiltersOrSearch) {
    return (
      <DialogStub text="По вашему запросу ничего не найдено." buttonText="Сбросить фильтры" onClick={resetFilters} />
    );
  }

  if (isEmpty && !hasSelectedFiltersOrSearch && emptyState) {
    return <DialogStub {...emptyState} withPlus />;
  }

  return children;
};

export default StubWrapper;
