import { AlertsStore, IAlertsStore } from '../AlertsStore';
import { DictionariesStore, IDictionariesStore } from '../DictionariesStore';
import { IModalsStore, ModalsStore } from '../ModalsStore/ModalsStore';
import { IPushSubscribeStore, PushSubscribeStore } from '../PushSubscribeStore';
import { IUIStore, UIStore } from '../UIStore';
import { IUserStore, UserStore } from '../UserStore';

export interface IRootStore {
  userStore: IUserStore;
  dictionariesStore: IDictionariesStore;
  alertsStore: IAlertsStore;
  uiStore: IUIStore;
  pushSubscribeStore: IPushSubscribeStore;
  modalsStore: IModalsStore;
}

export class RootStore implements IRootStore {
  readonly userStore: IUserStore = new UserStore({ rootStore: this });
  readonly dictionariesStore = new DictionariesStore();
  readonly alertsStore = new AlertsStore();
  readonly uiStore = new UIStore();
  readonly modalsStore: IModalsStore = new ModalsStore();
  readonly pushSubscribeStore: IPushSubscribeStore = new PushSubscribeStore({ rootStore: this });
}
