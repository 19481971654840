import * as React from 'react';

import InfoGrid from '@/components/InfoGrid';
import { RefusedWithInfoData } from '@/entities/lead';
import { Nullable } from '@/types/values';

import { useFormattedInfo } from './useFormattedInfo';

type Props = {
  data: Nullable<RefusedWithInfoData>;
};

const LeadResultContent: React.FC<Props> = ({ data }: Props) => {
  const { leadInfo, managerInfo } = useFormattedInfo(data);

  return (
    <>
      {leadInfo && <InfoGrid title="Данные заявки" info={leadInfo} />}
      {managerInfo && <InfoGrid title="За кем закреплена" info={managerInfo} />}
    </>
  );
};

export default React.memo(LeadResultContent);
