export enum CreateLeadResultType {
  success = 'success',
  refused = 'refused',
  refusedWithInfo = 'refusedWithInfo',
  refusedWithInfoList = 'refusedWithInfoList',
}

export enum CreateClientResultType {
  successClient = 'successClient',
  successLead = 'successLead',
  refusedLead = 'refusedLead',
  refusedLeadWithInfo = 'refusedLeadWithInfo',
  refusedLeadWithInfoList = 'refusedLeadWithInfoList',
}
