import { Nullable } from '@/types/values';

import { capitalizeFirstLetter } from './capitalizeFistLetter';

type Params = {
  firstName: string;
  lastName?: string;
  reversed?: boolean;
  short?: boolean;
};

/**
 * Возвращает имя сотрудника
 * - в формате «Фамилия Имя» при параметре reversed: false (по умолчанию)
 * - в формате «Имя Фамилия» при параметре reversed: true
 * - в формате «Фамилия И.» при параметрах reversed: false, short: true,
 * - в формате «Имя Ф.» при параметрах reversed: true, short: true
 */
export const getFullName = ({ firstName, lastName, reversed, short }: Params) => {
  const firstNameCapitalized = capitalizeFirstLetter(firstName);
  const lastNameCapitalized = lastName ? capitalizeFirstLetter(lastName) : '';

  if (!lastName) {
    return firstNameCapitalized;
  }

  if (short) {
    const firstNameShort = `${firstNameCapitalized.charAt(0)}`;
    const lastNameShort = `${lastNameCapitalized.charAt(0)}`;

    return reversed ? `${firstNameCapitalized} ${lastNameShort}.` : `${lastNameCapitalized} ${firstNameShort}.`;
  }

  return reversed ? `${firstNameCapitalized} ${lastNameCapitalized}` : `${lastNameCapitalized} ${firstNameCapitalized}`;
};

export const getFullNameWithPatronymic = (person: {
  lastName?: Nullable<string>;
  firstName?: Nullable<string>;
  patronymic?: Nullable<string>;
}): string =>
  [person.lastName, person.firstName, person.patronymic]
    .filter(Boolean)
    .map((item) => capitalizeFirstLetter(item ?? ''))
    .join(' ');
