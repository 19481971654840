import * as React from 'react';

import Typo, { TypoElement, TypoView } from '../Typo';

import s from './InfoGrid.module.scss';

export type InfoItem = {
  label: string;
  value: string | number;
};

type InfoGridProps = {
  className?: string;
  title: string;
  info: InfoItem[][];
};

const InfoGrid: React.FC<InfoGridProps> = ({ className, title, info }) => {
  return (
    <div className={className}>
      <Typo className={s.info_title} view={TypoView.h5} element={TypoElement.h5}>
        {title}
      </Typo>
      <div className={s.info}>
        {info.map((infoRow, idx) => (
          <div className={s.info_row} key={idx}>
            {infoRow.map(({ label, value }) => (
              <div className={s.info_item} key={label}>
                <Typo view={TypoView.textSmall} element={TypoElement.span} color="text-grey">
                  {label}
                </Typo>
                <Typo element={TypoElement.span}>{value || '-'}</Typo>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoGrid;
