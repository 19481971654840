import { DictionaryFields } from './types';

export const DICTIONARIES_FOR_LEADS = [
  DictionaryFields.projects,
  DictionaryFields.placeTypes,
  DictionaryFields.employees,
  DictionaryFields.sources,
];

export const DICTIONARIES_FOR_CLIENTS = [
  DictionaryFields.projects,
  DictionaryFields.placeTypes,
  DictionaryFields.employees,
  DictionaryFields.sources,
];

export const DICTIONARIES_FOR_CLIENT_DETAILS = [
  DictionaryFields.projects,
  DictionaryFields.placeTypes,
  DictionaryFields.sources,
];
