import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLocalStore } from '@kts-front/hooks';

import { FixationForm } from '@/components/FixationForm';
import InfoGrid from '@/components/InfoGrid';
import { ILead } from '@/entities/lead';
import { useRootStore } from '@/stores/global/RootStore';
import LeadCreationModalStore, { LeadCreationModalStoreContext } from '@/stores/local/LeadCreationModalStore';

import BaseLeadCreationModal from '../BaseLeadCreationModal';

import { useFormattedInfo } from './useFormattedInfo';

import s from './LeadCreationFromLeadModal.module.scss';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
  refetch?: VoidFunction;
  lead: ILead | null;
};

const LeadCreationFromLeadModal: React.FC<Props> = ({ opened, onClose, onOpen, refetch, lead }: Props) => {
  const rootStore = useRootStore();
  const { store } = useLocalStore(
    () =>
      new LeadCreationModalStore({
        clientId: lead?.clientId ?? null,
        rootStore,
        onClose,
        onOpen,
        refetch,
        defaultValues: { inn: lead?.inn },
      }),
  );

  const { clientInfo, subAgentInfo } = useFormattedInfo(lead);

  React.useEffect(() => {
    store.clientId.change(lead?.clientId ?? null);
    store.fixationForm.inn.change(lead?.inn ?? '');
    store.fixationForm.inn.setDisabled(Boolean(lead?.inn));
  }, [lead?.id]);

  return (
    <LeadCreationModalStoreContext.Provider value={store}>
      <BaseLeadCreationModal opened={opened}>
        {clientInfo && (
          <div className={s.form__client}>
            <InfoGrid title="Клиент" info={clientInfo} />
            {subAgentInfo && <InfoGrid title="Субагент" info={subAgentInfo} />}
          </div>
        )}
        <FixationForm formModel={store.fixationForm} />
      </BaseLeadCreationModal>
    </LeadCreationModalStoreContext.Provider>
  );
};

export default observer(LeadCreationFromLeadModal);
