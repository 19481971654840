import cn from 'classnames';
import * as React from 'react';

import ResultModal from '@/components/ResultModal';
import { LeadResultModalState } from '@/entities/lead';
import { CreateLeadResultType } from '@/entities/modal';

import LeadResultContent from '../LeadResultContent';

import { LEAD_RESULT_CONTENT_PROPS_CONFIG } from './config';

import s from './CreateLeadResultModal.module.scss';

type Props = {
  state: LeadResultModalState | null;
  onClose: VoidFunction;
  onSubmit?: VoidFunction;
  withBackdrop?: boolean;
};

const CreateLeadResultModal: React.FC<Props> = ({ state, onSubmit, onClose, withBackdrop }) => {
  const stateType = !state ? CreateLeadResultType.refused : state.type;
  const contentProps = LEAD_RESULT_CONTENT_PROPS_CONFIG[stateType];

  const data =
    state?.type === CreateLeadResultType.refusedWithInfo || state?.type === CreateLeadResultType.refusedWithInfoList
      ? state.data
      : null;

  const description =
    state?.type === CreateLeadResultType.refusedWithInfo || state?.type === CreateLeadResultType.refusedWithInfoList
      ? state.data.crmRejectionInfo?.message || contentProps.description
      : contentProps.description;

  return (
    <ResultModal
      opened={Boolean(state)}
      onClose={onClose}
      onSubmit={onSubmit}
      withBackdrop={withBackdrop}
      {...contentProps}
      description={description}
      iconClassName={cn(stateType !== CreateLeadResultType.success && s['content__refused-icon'])}
    >
      <LeadResultContent data={data} />
    </ResultModal>
  );
};

export default React.memo(CreateLeadResultModal);
