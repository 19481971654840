import { action, computed, makeObservable } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { BankDetailsServer, IBankDetails } from '@/entities/bankDetails';
import { InputModel } from '@/models/InputModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { IRootStore } from '@/stores/global/RootStore';
import {
  validateBic,
  validateCorrespondentAccount,
  validateNotEmpty,
  validatePaymentAccount,
} from '@/utils/validators';

import { BankDetailsModel } from '../bankDetails/BankDetailsModel';

import { BANK_DETAILS_EDITING_FORM_FIELDS, BankDetailsEditingFormFields } from './config';

export class BankDetailsFieldsEditingModel {
  readonly [BankDetailsEditingFormFields.bankName]: InputModel;
  readonly [BankDetailsEditingFormFields.bic]: InputModel;
  readonly [BankDetailsEditingFormFields.paymentAccount]: InputModel;
  readonly [BankDetailsEditingFormFields.correspondentAccount]: InputModel;

  readonly submitStage: LoadingStageModel = new LoadingStageModel();

  private _creatingRequest = apiStore.createRequest({ method: 'POST', url: apiUrls.bank.bankDetailsCreate });
  private _editingRequest = apiStore.createRequest({ method: 'POST', url: apiUrls.bank.bankDetailsEdit });
  private readonly _modalState: ToggleModel = new ToggleModel();
  private readonly _isServerErrorModel = new ValueModel(false);
  private _rootStore: IRootStore;

  constructor({
    bankName,
    bic,
    paymentAccount,
    correspondentAccount,
    rootStore,
  }: IBankDetails & { rootStore: IRootStore }) {
    this[BankDetailsEditingFormFields.bankName] = new InputModel({
      name: 'name',
      caption: 'Наименование банка',
      required: true,
      initialValue: bankName,
      validators: [validateNotEmpty()],
    });
    this[BankDetailsEditingFormFields.bic] = new InputModel({
      name: 'bic',
      caption: 'БИК',
      required: true,
      initialValue: bic,
      validators: [validateNotEmpty(), validateBic()],
    });
    this[BankDetailsEditingFormFields.paymentAccount] = new InputModel({
      name: 'paymentAccount',
      caption: 'Расчетный счет',
      required: true,
      initialValue: paymentAccount,
      validators: [validateNotEmpty(), validatePaymentAccount()],
    });
    this[BankDetailsEditingFormFields.correspondentAccount] = new InputModel({
      name: 'correspondentAccount',
      caption: 'Корреспондентский счет',
      required: true,
      initialValue: correspondentAccount,
      validators: [validateNotEmpty(), validateCorrespondentAccount()],
    });
    this._rootStore = rootStore;

    makeObservable<this, '_fieldsToValidate' | '_requiredFields'>(this, {
      isServerError: computed,
      isError: computed,
      isRequiredFieldsFilled: computed,
      _fieldsToValidate: computed,
      _requiredFields: computed,
      isModalOpen: computed,
      isBankDetailsCreated: computed,

      validate: action.bound,
      submit: action.bound,
    });
  }

  get isServerError(): boolean {
    return this._isServerErrorModel.value;
  }

  get isError(): boolean {
    let isError = false;

    this._fieldsToValidate.forEach((field) => {
      if (this[field].isError) {
        isError = true;
      }
    });

    return isError;
  }

  get isRequiredFieldsFilled(): boolean {
    return this._requiredFields.every((field) => !!this[field].value);
  }

  get isModalOpen(): boolean {
    return this._modalState.isOpen;
  }

  get isBankDetailsCreated(): boolean {
    return !!this._rootStore.userStore.user?.agency?.bankDetails;
  }

  openModal = () => {
    this._modalState.open();
  };

  closeModal = () => {
    this._modalState.close();
    this.resetErrors();
    this.resetServerError();
    this.updateFieldsByBankDetailsModel();
  };

  async submit(): Promise<BaseResponse> {
    const isError = this.validate();
    const user = this._rootStore.userStore.user;

    if (this.submitStage.isLoading || isError || !user) {
      return {
        isError: true,
      };
    }

    this.submitStage.loading();

    const data: BankDetailsServer = {
      bank_name: this[BankDetailsEditingFormFields.bankName].value,
      bic: this[BankDetailsEditingFormFields.bic].value,
      payment_account: this[BankDetailsEditingFormFields.paymentAccount].value,
      correspondent_account: this[BankDetailsEditingFormFields.correspondentAccount].value,
    };

    const response = await (
      this.isBankDetailsCreated ? this._editingRequest : this._creatingRequest
    ).call<BankDetailsServer>({
      data,
    });

    if (response.isError) {
      this._isServerErrorModel.change(true);

      this.submitStage.error();

      return {
        isError: true,
      };
    }

    this._rootStore.alertsStore.addAlert({
      message: this.isBankDetailsCreated
        ? 'Банковские реквизиты успешно обновлены'
        : 'Банковские реквизиты успешно созданы',
    });
    user.agency.updateBankDetails(BankDetailsModel.fromJson(response.data));
    this.submitStage.success();
    this.closeModal();

    return {
      isError: false,
    };
  }

  validate = (): boolean => {
    this._fieldsToValidate.forEach((field) => {
      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();
    }

    return this.isError;
  };

  resetServerError = (): void => {
    this._isServerErrorModel.change(false);
  };

  private get _fieldsToValidate(): BankDetailsEditingFormFields[] {
    return BANK_DETAILS_EDITING_FORM_FIELDS;
  }

  private get _requiredFields(): BankDetailsEditingFormFields[] {
    return this._fieldsToValidate.filter((field) => this[field].required) as BankDetailsEditingFormFields[];
  }

  private scrollToErrorField = () => {
    const errorField = this._fieldsToValidate.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  private resetErrors = (): void => {
    this._fieldsToValidate.forEach((field) => {
      this[field].resetError();
    });
  };

  private updateFieldsByBankDetailsModel = () => {
    const agencyModel = this._rootStore.userStore.user?.agency;

    if (!agencyModel || !agencyModel.bankDetails) {
      this[BankDetailsEditingFormFields.bankName].change('');
      this[BankDetailsEditingFormFields.bic].change('');
      this[BankDetailsEditingFormFields.paymentAccount].change('');
      this[BankDetailsEditingFormFields.correspondentAccount].change('');

      return;
    }

    const bankDetails = agencyModel.bankDetails;

    this[BankDetailsEditingFormFields.bankName].change(bankDetails.bankName);
    this[BankDetailsEditingFormFields.bic].change(bankDetails.bic);
    this[BankDetailsEditingFormFields.paymentAccount].change(bankDetails.paymentAccount);
    this[BankDetailsEditingFormFields.correspondentAccount].change(bankDetails.correspondentAccount);
  };

  static fromBankDetailsModel({
    model,
    rootStore,
  }: {
    model: BankDetailsModel | null;
    rootStore: IRootStore;
  }): BankDetailsFieldsEditingModel {
    return new BankDetailsFieldsEditingModel({
      bankName: model?.bankName || '',
      bic: model?.bic || '',
      paymentAccount: model?.paymentAccount || '',
      correspondentAccount: model?.correspondentAccount || '',
      rootStore,
    });
  }
}
