import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useShowAlerts } from '@/components/Alerts';
import Alerts from '@/components/Alerts/Alerts';
import Header from '@/components/Header';
import NotificationsSubscribeModal from '@/components/NotificationsSubscribeModal';
import { useRootStore } from '@/stores/global/RootStore';

const App: React.FC = () => {
  const isLoginPage = useMatch('/');

  const showHeader = !isLoginPage;
  const { pushSubscribeStore } = useRootStore();

  const showAlerts = useShowAlerts();

  return (
    <>
      {showHeader && <Header />}
      <Outlet />
      {showAlerts && <Alerts />}
      {pushSubscribeStore.canUsePushs && <NotificationsSubscribeModal />}
    </>
  );
};

export default observer(App);
