import * as React from 'react';

import Loader from '../Loader';

import s from './LoadingStub.module.scss';

type LoadingStubProps = {
  local?: boolean;
};

const LoadingStub: React.FC<LoadingStubProps> = ({ local }) => {
  if (local) {
    return (
      <div className={s.loading_local}>
        <Loader />
      </div>
    );
  }

  return <Loader className={s.loading_global} />;
};

export default LoadingStub;
