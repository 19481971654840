import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { PlaceTypeModel } from '@/models/PlaceTypeModel';
import { useRootStore } from '@/stores/global/RootStore';

import PlaceTypeContent from './PlaceTypeContent/PlaceTypeContent';

type Props = {
  placeType: string;
};

const PlaceType: React.FC<Props> = ({ placeType }: Props) => {
  const { dictionariesStore } = useRootStore();
  const placeTypeEntity = React.useMemo(
    (): PlaceTypeModel | null =>
      dictionariesStore.placeTypes.items.find((placeTypeModel) => placeTypeModel.slug === placeType) || null,
    [dictionariesStore.placeTypes.items, placeType],
  );

  return placeTypeEntity && <PlaceTypeContent placeType={placeTypeEntity} />;
};

export default observer(PlaceType);
