import * as React from 'react';

import SelectHorizontal, { SelectHorizontalProps } from './SelectHorizontal';
import SelectVertical, { SelectVerticalProps } from './SelectVertical';

type SelectProps<V extends string | number> = (
  | ({
      variant: 'horizontal';
    } & SelectHorizontalProps<V>)
  | ({
      variant: 'vertical';
    } & SelectVerticalProps<V>)
) &
  (
    | {
        multiple: true;
        value: Set<V>;
      }
    | {
        multiple: false;
        value: V | null;
      }
  );

const Select = <V extends string | number = string>(
  { value, ...props }: SelectProps<V>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement | null => {
  const finalProps = { ...props, values: value instanceof Set ? value : new Set(value ? [value] : []) };

  switch (props.variant) {
    case 'horizontal': {
      return <SelectHorizontal {...finalProps} ref={ref} />;
    }

    case 'vertical': {
      return <SelectVertical {...finalProps} ref={ref} />;
    }

    default: {
      return null;
    }
  }
};

export default React.forwardRef(Select) as typeof Select;
