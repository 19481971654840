import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

const ErrorText = (): React.ReactElement => {
  const { isPhone } = useRootStore().uiStore;

  return <>Внутренняя ошибка сервера.{isPhone ? <br /> : ' '}Попробуйте позже.</>;
};

export default observer(ErrorText);
