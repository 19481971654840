import { RouteSegment } from '@/configs/routes';

//Для сложносоставных сегментов
const toCamelCase = (section: string): string => section.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

export const getRouteSegment = (pathname: string): RouteSegment => {
  const section = pathname.split('/')[1];

  const normalizedSection = toCamelCase(section);

  return RouteSegment[normalizedSection as keyof typeof RouteSegment];
};
