import { BankDetailsServer, IBankDetails } from '@/entities/bankDetails';

export class BankDetailsModel implements IBankDetails {
  readonly bankName;
  readonly bic;
  readonly paymentAccount;
  readonly correspondentAccount;

  constructor({ bankName, bic, paymentAccount, correspondentAccount }: IBankDetails) {
    this.bankName = bankName;
    this.bic = bic;
    this.paymentAccount = paymentAccount;
    this.correspondentAccount = correspondentAccount;
  }

  static fromJson(raw: BankDetailsServer): BankDetailsModel {
    return new BankDetailsModel({
      bankName: raw.bank_name,
      bic: raw.bic,
      paymentAccount: raw.payment_account,
      correspondentAccount: raw.correspondent_account,
    });
  }
}
