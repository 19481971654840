import { action, computed, makeObservable, observable } from 'mobx';
import { createRef } from 'react';

import { Validator } from '@/types/validator';

import { ValueModel } from './ValueModel';

export type BaseFieldModelParams<V> = {
  initialValue: V;
  name: string;
  disabled?: boolean;
  required?: boolean;
  caption?: string;
  subcaption?: string;
  validators?: Validator<V>[];
};

export class BaseFieldModel<V = string, E extends HTMLElement = HTMLElement> extends ValueModel<V> {
  private _required: boolean;
  private _disabled: boolean;
  // Флаг установки наличия ошибки у поля (отдельно от самого поля с ошибкой error)
  readonly hasErrorModel = new ValueModel<boolean>(false);

  readonly ref = createRef<E>();
  readonly name: string;
  readonly caption: string;
  readonly subcaption: string;

  constructor({
    initialValue,
    name,
    disabled = false,
    required = false,
    caption = '',
    subcaption = '',
    validators = [],
  }: BaseFieldModelParams<V>) {
    super(initialValue, validators);

    this.name = name;
    this._disabled = disabled;
    this._required = required;
    this.caption = caption;
    this.subcaption = subcaption;

    makeObservable<this, '_required' | '_disabled'>(this, {
      _required: observable,
      _disabled: observable,
      required: computed,
      hasError: computed,
      disabled: computed,
      scrollToField: action.bound,
      setDisabled: action.bound,
      changeRequired: action.bound,
    });
  }

  get required(): boolean {
    return this._required;
  }

  get hasError(): boolean {
    return this.hasErrorModel.value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  setHasError = (hasError: boolean): void => {
    this.hasErrorModel.change(hasError);
  };

  scrollToField(): void {
    setTimeout(() => {
      if (this.ref.current) {
        this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 0);
  }

  changeRequired(value: boolean): void {
    this._required = value;
  }

  setDisabled(value: boolean): void {
    this._disabled = value;
  }
}
