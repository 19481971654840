import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon from '@/components/Icon';
import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import s from './NotificationsSubscribeModal.module.scss';

const NotificationsSubscribeModal: React.FC = () => {
  const { pushSubscribeStore } = useRootStore();

  React.useEffect(() => {
    pushSubscribeStore.initSubscribe();
  }, [pushSubscribeStore.canSubscribe]);

  return (
    <Modal
      className={s.modal}
      variant={ModalVariant.Confirm}
      opened={pushSubscribeStore.modal.isOpen}
      onClose={pushSubscribeStore.modal.close}
      primaryButtonOnClick={pushSubscribeStore.trySubscribe}
      primaryButtonText="Подписаться"
      secondaryButtonOnClick={pushSubscribeStore.modal.close}
      secondaryButtonText="Позже"
      contentClassName={s.modal__content}
      buttonClassName={s.modal__btn}
      showCloseButton={false}
      ariaLabel="Чтобы получать уведомления по клиентам - подпишитесь"
      title={<Typo view={TypoView.h5}>Чтобы получать уведомления по клиентам - подпишитесь</Typo>}
    >
      <Icon iconName="IconNotificationsSubscribe" />
    </Modal>
  );
};

export default observer(NotificationsSubscribeModal);
