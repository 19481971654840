import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import { ButtonSize } from '@/components/Button';
import CopyButton from '@/components/CopyButton';
import DialogStub from '@/components/DialogStub';
import CardField from '@/components/leads/CardField';
import LeadActions from '@/components/leads/LeadActions';
import LeadName from '@/components/leads/LeadName';
import LeadStatus from '@/components/leads/LeadStatus';
import Loader from '@/components/Loader';
import PlaceType from '@/components/PlaceType';
import Typo from '@/components/Typo';
import { ILead } from '@/entities/lead';
import { withDictionaries } from '@/hocs/withDictionaries';
import { PhoneNumber } from '@/models/PhoneNumber';
import { DICTIONARIES_FOR_LEADS } from '@/stores/global/DictionariesStore';
import { useRootStore } from '@/stores/global/RootStore';
import { formatDDMMYY } from '@/utils/formatDate';

import LeadCreationFromLeadModal from '../../LeadCreationFromLeadModal';

import { getName } from './utils';

import s from './DetailLeadContent.module.scss';

type Props = {
  isLoading?: boolean;
  isError?: boolean;
  lead: ILead | null;
  refetch?: VoidFunction;
};

const DetailLeadContent: React.FC<Props> = ({ isError, isLoading, lead, refetch }) => {
  const {
    userStore: { isBlockedUser },
  } = useRootStore();

  const { opened: openedCreateLead, open: openCreateLead, close: closeCreateLead } = useToggleState();

  if (isLoading) {
    return (
      <div className={s.content__stub}>
        <Loader />
      </div>
    );
  }

  if (isError || !lead) {
    return (
      <div className={s.content__stub}>
        <DialogStub text="Что-то пошло не так. Пожалуйста, попробуйте позже" />
      </div>
    );
  }

  return (
    <div>
      <LeadName
        className={s.content__name}
        item={lead}
        textClassName={s['content__name-text']}
        statusClassName={s['content__name-status']}
      />
      <div className={s.content__phone}>
        <Typo block className={s['content__phone-text']}>
          {PhoneNumber.format(lead.phone)}
        </Typo>
        <CopyButton textToCopy={PhoneNumber.format(lead.phone)} alertText="Телефон скопирован" />
      </div>

      <div className={s.content__details}>
        <CardField title="Дата создания" value={formatDDMMYY(lead.createdDate)} />
        <CardField title="Проект" value={lead.project} />
        <CardField title="Закреплен до" value={lead.expiryDate ? formatDDMMYY(lead.expiryDate) : ''} />
        <CardField title="Помещение" value={<PlaceType placeType={lead.placeType} />} />
        <CardField title="Статус" value={<LeadStatus item={lead} />} />
        <CardField title="Сотрудник" value={getName(lead.managerFullName)} />
      </div>
      <CardField title="Комментарий" value={lead.comment} />
      {!isBlockedUser && (
        <>
          <LeadActions
            item={lead}
            className={s.content__actions}
            buttonClassName={s['content__actions-button']}
            buttonSize={ButtonSize.medium}
            openCreateLead={openCreateLead}
          />
          <LeadCreationFromLeadModal
            lead={lead}
            opened={openedCreateLead}
            onClose={closeCreateLead}
            onOpen={openCreateLead}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};

export default withDictionaries({ Component: observer(DetailLeadContent), dictionaries: DICTIONARIES_FOR_LEADS });
