import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import Typo, { TypoView } from '@/components/Typo';
import { routerUrls, RouteSegment } from '@/configs/routes';
import { useRootStore } from '@/stores/global/RootStore';
import { getRouteSegment } from '@/utils/getRouteSegment';

import BackButton from '../BackButton';

import Logo from './Logo';
import { ButtonPageTitles, PageTitles } from './config';

import s from './PageTitle.module.scss';

type Props = {
  className?: string;
};

const PageTitle: React.FC<Props> = ({ className }) => {
  const { pathname } = useLocation();
  const routeSegment = getRouteSegment(pathname);

  const { isPhone } = useRootStore().uiStore;

  return (
    <div className={cn(s.title, className)}>
      {isPhone && routeSegment && ButtonPageTitles[routeSegment] ? (
        <BackButton
          text={ButtonPageTitles[routeSegment]?.title ?? ''}
          back
          backUrl={ButtonPageTitles[routeSegment]?.backUrl ?? routerUrls.main.create()}
        />
      ) : (
        <>
          <Logo />
          <Typo className={s.title_caption} view={TypoView.caption} uppercase>
            {routeSegment ? PageTitles[routeSegment] : PageTitles[RouteSegment.main]}
          </Typo>
        </>
      )}
    </div>
  );
};

export default observer(PageTitle);
