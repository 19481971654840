import cn from 'classnames';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonSize, ButtonType } from '../Button';
import Icon from '../Icon';
import { TypoView } from '../Typo';

import s from './BackButton.module.scss';

export type BackButtonProps = {
  className?: string;
  text: string;
} & ({ back: true; backUrl?: string } | { back: false; onClick: VoidFunction });

const BackButton: React.FC<BackButtonProps> = React.memo(({ className, text, ...props }) => {
  const navigate = useNavigate();
  const handleClick = React.useCallback(() => {
    if (!props.back) {
      props.onClick();

      return;
    }

    if (props.back && props.backUrl) {
      navigate(props.backUrl);

      return;
    }

    navigate(-1);
  }, [props]);

  return (
    <Button
      before={<Icon iconName="IconChevronLeft" size={20} />}
      className={cn(className, s.back, s.back__btn)}
      size={ButtonSize.small}
      view={ButtonType.secondary}
      textView={TypoView.textSmall}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
});

export default BackButton;
