import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon, { IconName } from '@/components/Icon';
import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useUiStore } from '@/stores/global/RootStore';

import s from './ResultModal.module.scss';

export type ResultModalProps = React.PropsWithChildren<
  {
    opened: boolean;
    onClose: VoidFunction;
    onSubmit?: VoidFunction;
    withBackdrop?: boolean;
  } & ResultModalContentProps
>;

export type ResultModalContentProps = {
  title: string;
  description?: string;
  buttonText?: string;
  iconName: IconName;
  iconHeight?: number;
  iconClassName?: string;
  className?: string;
};

const ResultModal: React.FC<ResultModalProps> = ({
  opened,
  onClose,
  onSubmit,
  withBackdrop,
  children,
  title,
  description,
  buttonText,
  iconName,
  iconHeight,
  iconClassName,
  className,
}) => {
  const { isPhone } = useUiStore();

  return (
    <Modal
      variant={ModalVariant.RightWindow}
      primaryButtonText={onSubmit && buttonText}
      primaryButtonOnClick={onSubmit}
      opened={opened}
      ariaLabel={title}
      isScrollable={false}
      className={className}
      onClose={onClose}
      withBackdrop={withBackdrop}
    >
      <div className={s.content}>
        <div className={s.content_result}>
          <Icon iconName={iconName} height={iconHeight} width={300} className={iconClassName} />
          <Typo view={TypoView.h2} element={TypoElement.h2}>
            {title}
          </Typo>
          {description && <Typo view={isPhone ? TypoView.textSmall : TypoView.text}>{description}</Typo>}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default observer(ResultModal);
