import { format, isToday, isTomorrow, isYesterday } from 'date-fns';

import { MONTHS_GEN } from './dateCongif';

// 16.08.24
export const formatDDMMYY = (date: Date | string): string => format(date, 'dd.MM.yy');

// 16.08.2024
export const formatDDMMYYYY = (date: Date | string): string => format(date, 'dd.MM.yyyy');

// 23:59
export const formatTimeHMM = (date: Date | string): string => format(date, 'H:mm').toLowerCase();

// Сегодня | Завтра | 16.08.24
export const formatDateText = ({
  date,
  format = formatDDMMYY,
}: {
  date: Date;
  format?: (date: Date) => string;
}): string => {
  if (isToday(date)) {
    return 'Сегодня';
  }

  if (isTomorrow(date)) {
    return 'Завтра';
  }

  if (isYesterday(date)) {
    return 'Вчера';
  }

  return format(date);
};

// 16 августа 2024
export const formatDateDDMMMMYYYY = (date: Date): string =>
  `${date.getDate()} ${MONTHS_GEN[date.getMonth()].toLowerCase()} ${date.getFullYear()}`;

// 16 августа 2024, 23:59
export const formatDateDDMMMMYYYYHMM = (date: Date) =>
  `${formatDateText({ date, format: formatDateDDMMMMYYYY })}, ${formatTimeHMM(date)}`;
