export class PhoneNumber {
  static fromJson(rawPhone: string): string {
    return rawPhone.startsWith('+') ? rawPhone : `+${rawPhone}`;
  }

  static format(phone: string | number) {
    try {
      return phone
        .toString()
        .replace(/\D/g, '')
        .replace(/^(7|8)?(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');
    } catch (error) {
      return '';
    }
  }

  static removeCountryCode(phone: string): string {
    const unformattedPhone = phone;

    return unformattedPhone.startsWith('+7') ? unformattedPhone.slice(2, unformattedPhone.length) : unformattedPhone;
  }
}
