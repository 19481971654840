import { computed, makeObservable } from 'mobx';

import { BreakpointEnum, BREAKPOINTS } from '@/configs/width';
import { ValueModel } from '@/models/ValueModel';

import { IUIStore } from './types';

export class UIStore implements IUIStore {
  private widthModel: ValueModel<number> = new ValueModel(window.innerWidth);

  constructor() {
    makeObservable(this, {
      width: computed,
      breakpoint: computed,
      isPhone: computed,
      isTablet: computed,
      isLessThanDesktop: computed,
      isTinyDesktop: computed,
    });
  }

  get width(): number {
    return this.widthModel.value;
  }

  get breakpoint(): BreakpointEnum {
    if (this.widthModel.value >= BREAKPOINTS[BreakpointEnum.xl]) {
      return BreakpointEnum.xl;
    }

    if (this.widthModel.value >= BREAKPOINTS[BreakpointEnum.lg]) {
      return BreakpointEnum.lg;
    }

    if (this.widthModel.value >= BREAKPOINTS[BreakpointEnum.md]) {
      return BreakpointEnum.md;
    }

    if (this.widthModel.value >= BREAKPOINTS[BreakpointEnum.sm]) {
      return BreakpointEnum.sm;
    }

    return BreakpointEnum.xs;
  }

  get isPhone(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.sm];
  }

  get isTablet(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.xm] && this.width >= BREAKPOINTS[BreakpointEnum.sm];
  }

  // phone + tablet ranges
  get isLessThanDesktop(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.xm];
  }

  get isTinyDesktop(): boolean {
    return this.width < BREAKPOINTS[BreakpointEnum.md] && this.width >= BREAKPOINTS[BreakpointEnum.xm];
  }

  setWidth = () => {
    this.widthModel.change(window.innerWidth);
  };
}
