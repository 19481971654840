import cn from 'classnames';
import * as React from 'react';

import { PlaceTypeModel } from '@/models/PlaceTypeModel';

import Typo, { TypoView } from '../../Typo';

import s from './PlaceTypeContent.module.scss';

type PlaceTypeContentProps = {
  placeType: PlaceTypeModel;
  className?: string;
};

const PlaceTypeContent = ({
  placeType,
  className,
}: PlaceTypeContentProps): React.ReactElement<PlaceTypeContentProps> => {
  return (
    <div className={cn(s['place-type'], className)}>
      {placeType.logo && (
        <div className={s['place-type__icon-container']} dangerouslySetInnerHTML={{ __html: placeType.logo }} />
      )}
      <Typo view={TypoView.textSmall} className={s['place-type__title']}>
        {placeType.label}
      </Typo>
    </div>
  );
};

export default PlaceTypeContent;
