export enum LoadingStage {
  notStarted = 'notStarted',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

export enum ApiErrorCode {
  conflict = 'conflict',
  badRequest = 'bad_request',
  nonuniqueBid = 'nonunique_bid',
  bidExistError = 'bid_exist_error',
  clientDuplicate = 'client_duplicate',
  notFound = 'not_found',
  bookingFail = 'booking_fail',
}

export type ApiErrorData<C extends ApiErrorCode = ApiErrorCode, D extends Record<any, any> = Record<any, any>> = {
  status: 'error';
  code: C;
  message: string | null;
  data?: D;
};

export type ApiFieldsErrorData<Fields extends string> = {
  fields: Partial<Record<Fields, string>>;
};

export type ApiErrorDataWithFields<Fields extends string = string> = ApiErrorData<
  ApiErrorCode.conflict | ApiErrorCode.badRequest,
  ApiFieldsErrorData<Fields>
>;
