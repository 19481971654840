import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import IconButton from '@/components/IconButton';
import Typo, { TypoElement, TypoView } from '@/components/Typo';

import s from './Confirm.module.scss';

export type ConfirmProps = {
  className?: string;
  contentClassName?: string;
  buttonClassName?: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: VoidFunction;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: VoidFunction;
  showCloseButton?: boolean;
  onClose: VoidFunction;
  title?: React.ReactNode;
  children?: React.ReactNode;
};

const Confirm: React.FC<ConfirmProps> = ({
  title,
  children,
  showCloseButton = true,
  onClose,
  className,
  contentClassName,
  buttonClassName,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}) => {
  const showButtons = primaryButtonText || secondaryButtonText;

  return (
    <div className={cn(s.content, className)}>
      {title && (
        <Typo
          className={cn(
            showCloseButton && s['content__title_with-close-btn'],
            s.content__title,
            !children && s['content__title_large-indent'],
          )}
          view={TypoView.h5}
          element={TypoElement.h5}
        >
          {title}
        </Typo>
      )}
      {children && (
        <Typo
          className={cn(showButtons && s['content__content_with-buttons'], contentClassName)}
          view={TypoView.text}
          element={TypoElement.h5}
        >
          {children}
        </Typo>
      )}
      {showButtons && (
        <div className={s.content_buttons}>
          {primaryButtonText && (
            <Button
              className={buttonClassName}
              view={ButtonType.primary}
              element="button"
              onClick={primaryButtonOnClick}
            >
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button
              className={buttonClassName}
              view={ButtonType.secondary}
              element="button"
              onClick={secondaryButtonOnClick}
            >
              {secondaryButtonText}
            </Button>
          )}
        </div>
      )}
      {showCloseButton && (
        <IconButton
          className={s.content_closeIcon}
          iconName="IconClose"
          onClick={onClose}
          size={24}
          color="grey-main"
        />
      )}
    </div>
  );
};

export default Confirm;
