import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingStub from '@/components/LoadingStub';
import { routerUrls } from '@/configs/routes';
import { ownLocalStorage, StorageKeys } from '@/models/LocaleStorageModel';
import { useRootStore } from '@/stores/global/RootStore';

type Params<P> = {
  Component: React.ComponentType<P>;
  forAuthorizedUser?: boolean;
  onlyAdmin?: boolean;
  onlyDirector?: boolean;
};

/* HOC для страниц доступных только авторизованным пользователям */
export const withAuth = <P extends Record<string, unknown>>({
  Component,
  forAuthorizedUser = true,
  onlyAdmin = false,
  onlyDirector = false,
}: Params<P>) =>
  observer((props: P) => {
    const { userStore } = useRootStore();
    const { authorized, authLoadingStage } = userStore;
    const navigate = useNavigate();

    const isExpectedRole = onlyAdmin ? userStore.isAdmin : onlyDirector ? userStore.isDirector : true;

    const needRedirect = React.useMemo(() => {
      if (!isExpectedRole) {
        return true;
      }

      if (authLoadingStage.isError && forAuthorizedUser) {
        return true;
      }

      return authLoadingStage.isSuccess && forAuthorizedUser ? !authorized : authorized;
    }, [authorized, forAuthorizedUser, isExpectedRole, authLoadingStage.isError, authLoadingStage.isSuccess]);

    React.useEffect(() => {
      if (!(needRedirect && authLoadingStage.isFinished)) {
        return;
      }

      let redirectLink = forAuthorizedUser ? '/' : routerUrls.main.create();

      // Если не получилось авторизоваться, то сохраняем урл
      if (authLoadingStage.isError && forAuthorizedUser) {
        ownLocalStorage.setItem(StorageKeys.savedUrl, window.location.pathname + window.location.search);
      }

      // Редиректим на сохраненный урл или разводяющую со страницы логина
      if (authLoadingStage.isSuccess && !forAuthorizedUser) {
        const savedUrl = ownLocalStorage.getItem(StorageKeys.savedUrl);
        redirectLink = savedUrl || routerUrls.main.create();

        if (savedUrl) {
          ownLocalStorage.removeItem(StorageKeys.savedUrl);
        }
      }

      navigate(redirectLink);
    }, [needRedirect, authLoadingStage.isFinished, navigate]);

    if (!authLoadingStage.isFinished || needRedirect) {
      return <LoadingStub />;
    }

    return <Component {...props} />;
  });
