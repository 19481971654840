export enum BankDetailsEditingFormFields {
  bankName = 'bankName',
  bic = 'bic',
  paymentAccount = 'paymentAccount',
  correspondentAccount = 'correspondentAccount',
}

export const BANK_DETAILS_EDITING_FORM_FIELDS: BankDetailsEditingFormFields[] = [
  BankDetailsEditingFormFields.bankName,
  BankDetailsEditingFormFields.bic,
  BankDetailsEditingFormFields.paymentAccount,
  BankDetailsEditingFormFields.correspondentAccount,
];
