import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import { ErrorText } from '@/components/Modal/ErrorText';
import { useLeadCreationModalStore } from '@/stores/local/LeadCreationModalStore';

import CreateLeadResultModal from '../CreateLeadResultModal';
import ErrorModal from '../ErrorModal';

type Props = React.PropsWithChildren<{
  opened: boolean;
}>;

const BaseLeadCreationModal: React.FC<Props> = ({ opened, children }: Props) => {
  const store = useLeadCreationModalStore();

  return (
    <>
      <Modal
        variant={ModalVariant.RightWindow}
        header="Заявка на фиксацию"
        ariaLabel="Заявка на фиксацию"
        error={store.isServerError && <ErrorText />}
        onCloseError={store.resetServerError}
        primaryButtonText="Отправить заявку"
        primaryButtonOnClick={store.handlePrimaryButtonClick}
        primaryButtonDisabled={store.isFieldError || store.isServerError}
        primaryButtonLoading={store.submitStage.isLoading}
        onClose={store.closeModal}
        opened={opened}
      >
        {children}
      </Modal>
      <ErrorModal opened={store.errorModalState.isOpen} onClose={store.errorModalState.close} />
      <CreateLeadResultModal
        state={store.resultModalState.value}
        onClose={store.closeResultModal}
        onSubmit={store.handleSubmitResultModal}
      />
    </>
  );
};

export default observer(BaseLeadCreationModal);
