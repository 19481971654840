export enum RouteSegment {
  // "Основной каталог"
  main = 'main',
  // "Список заявок"
  leads = 'leads',
  // "Список клиентов"
  clients = 'clients',
  // "Детальная страница клиента"
  client = 'client',
  // "Список сотрудников"
  employees = 'employees',
  // "Профиль агенства"
  agency = 'agency',
  // "Бронирование"
  booking = 'booking',
  // "Тарифная карта"
  rateCard = 'rate-card',
}
