import cn from 'classnames';
import * as React from 'react';

import Icon from '../Icon';
import Typo, { TypoAlign } from '../Typo';

import s from './Loader.module.scss';

type LoaderProps = {
  className?: string;
  loaderSize?: number;
  withCaption?: boolean;
  centered?: boolean;
};

const Loader: React.FC<LoaderProps> = ({ className, loaderSize = 40, withCaption = true, centered = false }) => {
  return (
    <div className={cn(s.loader, centered && s.loader_centered, className)}>
      <Icon iconName="IconLoading" size={loaderSize} color="black" />
      {withCaption && (
        <Typo className={s.loader__caption} textAlign={TypoAlign.center}>
          Загрузка...
        </Typo>
      )}
    </div>
  );
};

export default Loader;
