import * as React from 'react';

import { useStrictContext } from '@kts-front/hooks';

import { useOfflineError } from '@/hooks/useOfflineError';

import { useWidthSetting } from '../UIStore/hooks';

import { RootStore } from './RootStore';

type RootStoreContextValue = RootStore;

const RootStoreContext = React.createContext<RootStoreContextValue | null>(null);

export const RootStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const store = new RootStore();

  React.useEffect(() => {
    store.userStore.authorize();
  }, [store]);

  useOfflineError(store);

  useWidthSetting(store.uiStore.setWidth);

  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};

export const useRootStore = () => {
  const context = useStrictContext({ context: RootStoreContext, message: 'RootStoreContext was not provided' });

  return context;
};

export const useDictionariesStore = () => {
  return useRootStore().dictionariesStore;
};

export const useUiStore = () => {
  return useRootStore().uiStore;
};

export const useUserStore = () => {
  return useRootStore().userStore;
};

export const useLeadModalStore = () => {
  return useRootStore().modalsStore.leadModalStore;
};
