import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FixationFormModel } from '@/models/FixationFormModel';
import { useUiStore } from '@/stores/global/RootStore';

import Typo, { TypoElement, TypoView } from '../Typo';
import InputField from '../form/InputField';

import Comment from './Comment';
import { ProjectAndPlaceType } from './ProjectAndPlaceType';
import { SourceSelect } from './SourceSelect';

import s from './FixationForm.module.scss';

type FixationFormProps = {
  formModel: FixationFormModel;
  label?: string;
  className?: string;
};

const FixationForm = ({ formModel, label, className }: FixationFormProps): React.ReactElement<FixationFormProps> => {
  const { source, clientComment, isCommercialPlaceType, inn } = formModel;
  const { isPhone } = useUiStore();

  return (
    <div className={className}>
      {label && (
        <Typo className={s['fixation-form__fixation-title']} view={TypoView.h2} element={TypoElement.h2}>
          {label}
        </Typo>
      )}
      <ProjectAndPlaceType className={s['fixation-form__project-and-place-type']} formModel={formModel} />
      <SourceSelect className={s['fixation-form__source-select']} value={source.value} onChange={source.onChange} />
      <AnimatePresence>
        {isCommercialPlaceType && (
          <motion.div
            style={{ overflowY: 'hidden' }}
            initial={{
              height: 0,
              opacity: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
              marginBottom: isPhone ? 16 : 32,
            }}
            exit={{ height: 0, opacity: 0, marginTop: 0, marginBottom: 0 }}
          >
            <Typo
              className={s['fixation-form__inn']}
              view={isPhone ? TypoView.h4 : TypoView.h3}
              element={isPhone ? TypoElement.h4 : TypoElement.h3}
            >
              ИНН
            </Typo>
            <InputField {...inn.props} />
          </motion.div>
        )}
      </AnimatePresence>
      <Comment commentProps={clientComment.props} />
    </div>
  );
};

export default observer(FixationForm);
