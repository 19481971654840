import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Select from '@/components/form/Select/Select';
import { TypoElement, TypoView } from '@/components/Typo';
import Typo from '@/components/Typo/Typo';
import { DictionaryFields } from '@/stores/global/DictionariesStore';
import { useDictionariesStore, useUiStore } from '@/stores/global/RootStore';
import { Nullable } from '@/types/values';
import { withRequiredSymbol } from '@/utils/withRequiredSymbol';

import s from './SourceSelect.module.scss';

type Props = {
  className?: string;
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
};

const SourceSelect: React.FC<Props> = ({ className, onChange, value }) => {
  const dictionaries = useDictionariesStore();
  const { isPhone } = useUiStore();

  if (!dictionaries[DictionaryFields.sources].length) {
    return null;
  }

  return (
    <div className={className}>
      <Typo
        className={s['select-source__title']}
        view={isPhone ? TypoView.h4 : TypoView.h3}
        element={isPhone ? TypoElement.h4 : TypoElement.h3}
      >
        {withRequiredSymbol('Тип источника', false)}
      </Typo>
      <Select
        variant="vertical"
        className={s['select-source__select']}
        multiple={false}
        placeholder="Выберите тип источника"
        options={dictionaries[DictionaryFields.sources].items.map((item) => ({
          id: item.slug,
          label: item.title,
        }))}
        size="large"
        value={value}
        onChange={onChange}
        popupPosition={['bottom left']}
      />
    </div>
  );
};

export default observer(SourceSelect);
