import { CrmRejectionInfo, CrmRejectionInfoServer, ILead, LeadServer, LeadStatus } from '@/entities/lead';
import { BaseLeadModel } from '@/models/BaseLeadModel';
import { getFullName } from '@/utils/getFullName';

import { PhoneNumber } from './PhoneNumber';

export class LeadModel extends BaseLeadModel implements ILead {
  readonly managerId;
  readonly managerFullName;
  readonly fullName;
  readonly firstName;
  readonly lastName;
  readonly phone;
  readonly email;
  readonly subAgent;
  readonly crmRejectionInfo;
  readonly clientId;
  readonly inn;

  constructor({
    id,
    comment,
    createdDate,
    expiryDate,
    managerId,
    managerFullName,
    fullName,
    firstName,
    lastName,
    phone,
    placeType,
    project,
    status,
    email,
    subAgent,
    crmRejectionInfo,
    clientId,
    inn,
  }: ILead) {
    super({ id, status, placeType, createdDate, expiryDate, project, comment });
    this.managerId = managerId;
    this.managerFullName = managerFullName;
    this.fullName = fullName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = PhoneNumber.format(phone);
    this.email = email;
    this.subAgent = subAgent;
    this.crmRejectionInfo = crmRejectionInfo;
    this.clientId = clientId;
    this.inn = inn;
  }

  static fromJson(raw: LeadServer): LeadModel {
    const subAgent =
      raw.client.sub_agent?.name && raw.client.sub_agent?.phone
        ? {
            name: raw.client.sub_agent.name,
            phone: raw.client.sub_agent.phone,
          }
        : null;

    const crmRejectionInfo =
      raw.status === LeadStatus.nonUnique && raw.crm_rejection_info
        ? this.normalizeCrmRejectionInfo(raw.crm_rejection_info)
        : null;

    const phone = PhoneNumber.format(raw.client.phone);

    return new LeadModel({
      id: raw.id,
      comment: raw.comment,
      createdDate: raw.created_at,
      expiryDate: raw.hold_date,
      managerId: raw.client.manager.id,
      managerFullName: getFullName({
        firstName: raw.client.manager.first_name,
        lastName: raw.client.manager.last_name,
      }),
      fullName: getFullName({ firstName: raw.client.first_name, lastName: raw.client.last_name ?? undefined }),
      firstName: raw.client.first_name,
      lastName: raw.client.last_name ?? undefined,
      phone,
      email: raw.client.email ?? '',
      placeType: raw.place_type,
      project: raw.project.name,
      status: raw.status,
      subAgent,
      crmRejectionInfo,
      clientId: raw.client.id,
      inn: raw.client.inn,
    });
  }

  static normalizeCrmRejectionInfo(from: CrmRejectionInfoServer): CrmRejectionInfo {
    return {
      leadId: from.lead_id,
      manager: {
        name: from.manager?.name || null,
        phone: from.manager?.phone || null,
      },
      agency: {
        title: from.agency?.title || null,
      },
      message: from.message || null,
      holdDate: from.hold_date || null,
    };
  }
}
