import cn from 'classnames';
import * as React from 'react';

import { Color } from '@/types/colors';
import { getColor } from '@/utils/getColor';

import { TypoAlign, TypoElement, TypoView, TypoWeight } from './config';

import s from './Typo.module.scss';

export type TypoProps = {
  element?: TypoElement;
  uppercase?: boolean;
  block?: boolean;
  maxLines?: number;
  className?: string;
  style?: React.CSSProperties;
  view?: TypoView;
  textAlign?: TypoAlign;
  children: React.ReactNode;
  weight?: TypoWeight;
  color?: Color;
  underline?: boolean;
  onClick?: () => void;
} & React.AriaAttributes;

const Typo = (
  {
    element: Element = TypoElement.div,
    view = TypoView.text,
    children,
    textAlign,
    maxLines,
    block,
    uppercase,
    className,
    weight,
    color,
    underline,
    onClick,
    ...props
  }: TypoProps,
  ref: React.ForwardedRef<any>,
) => {
  return (
    <Element
      ref={ref}
      className={cn(
        s['typo'],
        view && s[`typo_view_${view}`],
        weight && s[`typo_weight_${weight}`],
        textAlign && s[`typo_text-align_${textAlign}`],
        maxLines === 1 && s['typo_ellipsis'],
        maxLines && maxLines > 1 && s['typo_multi-ellipsis'],
        block && s['typo_block'],
        uppercase && s['typo_uppercase'],
        underline && s['typo_underline'],
        className,
      )}
      style={
        { '--max-lines-count': maxLines, '--typo-color': color ? getColor(color) : undefined } as React.CSSProperties
      }
      onClick={onClick}
      {...props}
    >
      {children}
    </Element>
  );
};

export default React.forwardRef(Typo);
