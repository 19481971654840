import { AgencyServer } from '../agency';

export enum UserRole {
  manager = 'manager',
  director = 'director',
  fixator = 'fixator',
}

export const mapUserRoleToRights: Record<UserRole, string[]> = {
  [UserRole.director]: [
    'создавать заявки',
    'смотреть все заявки агентства',
    'выбирать сотрудника при заведении заявки для закрепления за ним',
    'изменять роли сотрудников и добавлять новых: руководителей, фиксаторов и менеджеров',
  ],
  [UserRole.fixator]: [
    'создавать заявки',
    'смотреть все заявки агентства',
    'выбирать сотрудника при заведении заявки для закрепления за ним',
    'изменять роли сотрудников и добавлять новых: фиксаторов и менеджеров',
  ],
  [UserRole.manager]: ['создавать заявки', 'смотреть только свои заявки'],
};

export type UserServer = {
  id: number;
  agency: AgencyServer;
  phone: string | null;
  email: string;
  external_id: number;
  last_name: string;
  first_name: string;
  role: UserRole;
  is_blocked?: boolean;
  unread_notifications_count: number;
};
