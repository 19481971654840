import { SortDirection } from '@/types/meta';
import { Nullable } from '@/types/values';

import { ProjectServer } from '../dictionaries';

export enum LeadStatus {
  unique = 'unique',
  wasUnique = 'wasunique',
  nonUnique = 'nonunique',
}

type ManagerServer = {
  id: number;
  first_name: string;
  last_name?: string;
};

type SubAgentServer = Nullable<{
  name: string;
  phone: string;
}>;

type ClientServer = {
  first_name: string;
  last_name?: Nullable<string>;
  email: Nullable<string>;
  phone: string;
  inn: Nullable<string>;
  id: number;
  manager: ManagerServer;
  sub_agent: SubAgentServer;
};

export type CrmRejectionInfoServer = {
  lead_id: string;
  manager: Nullable<{
    name: Nullable<string>;
    phone: Nullable<string>;
  }>;
  agency: Nullable<{
    title: Nullable<string>;
  }>;
  message?: Nullable<string>;
  hold_date?: Nullable<string>;
};

export type LeadServer = BaseLeadServer & {
  client: ClientServer;
  crm_rejection_info: Nullable<CrmRejectionInfoServer>;
};

export type LeadListServer = {
  bids: LeadServer[];
  total: number;
};

export type BaseLeadServer = {
  id: number;
  status: LeadStatus;
  place_type: string;
  created_at: string;
  hold_date?: string;
  project: Pick<ProjectServer, 'id' | 'name'>;
  comment: string;
};

export type BaseLeadListServer = {
  bids: BaseLeadServer[];
  total: number;
};

export enum SortPayloadFields {
  created_at = 'created_at',
  hold_date = 'hold_date',
}

export type SortPayload = {
  field: SortPayloadFields;
  direction: Exclude<SortDirection, 'none'>;
};

export type HoldDatePayload = {
  from: Nullable<string>;
  to: Nullable<string>;
};

export type LeadListPayload = {
  pagination: {
    limit: number;
    offset: number;
  };
  filters: {
    manager_ids: Nullable<number[]>;
    statuses: Nullable<LeadStatus[]>;
    project_ids: Nullable<number[]>;
    place_types: Nullable<string[]>;
    hold_date: Nullable<HoldDatePayload>;
  };
  sort: Nullable<SortPayload[]>;
  search: Nullable<string>;
};
