import * as React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ErrorBoundary } from '@/components/ErrorBoundary';
import { withAuth } from '@/hocs/withAuth';
import { withDictionaries } from '@/hocs/withDictionaries';
import App from '@/pages/App';
import {
  DICTIONARIES_FOR_CLIENT_DETAILS,
  DICTIONARIES_FOR_CLIENTS,
  DICTIONARIES_FOR_LEADS,
} from '@/stores/global/DictionariesStore';

import { routerUrls } from './routerUrls';

/* Страницы с предзагруженными словарями */
const LeadListWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/LeadList')),
  dictionaries: DICTIONARIES_FOR_LEADS,
});
const ClientListWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/ClientList')),
  dictionaries: DICTIONARIES_FOR_CLIENTS,
});
const ClientDetailsWithDictionaries = withDictionaries({
  Component: React.lazy(() => import('@/pages/ClientDetail')),
  dictionaries: DICTIONARIES_FOR_CLIENT_DETAILS,
});

/* Страницы доступные только авторизованным пользователям */
const Main = withAuth({ Component: React.lazy(() => import('@/pages/Main')) });
const LeadList = withAuth({ Component: LeadListWithDictionaries });
const ClientList = withAuth({ Component: ClientListWithDictionaries });
const ClientDetail = withAuth({ Component: ClientDetailsWithDictionaries });
const EmployeeList = withAuth({ Component: React.lazy(() => import('@/pages/EmployeeList')), onlyAdmin: true });
const AgencyProfile = withAuth({ Component: React.lazy(() => import('@/pages/AgencyProfile')), onlyDirector: true });
const BookingPage = withAuth({ Component: React.lazy(() => import('@/pages/BookingPage')) });
const RateCard = withAuth({ Component: React.lazy(() => import('@/pages/RateCard')) });

/* Страницы доступные только неавторизованным пользователям */
const Login = withAuth({ Component: React.lazy(() => import('@/pages/Login')), forAuthorizedUser: false });

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: routerUrls.main.mask,
        element: <Main />,
      },
      {
        path: routerUrls.leadList.mask,
        element: <LeadList />,
      },
      {
        path: routerUrls.clientList.mask,
        element: <ClientList />,
      },
      {
        path: routerUrls.clientDetail.mask,
        element: <ClientDetail />,
      },
      {
        path: routerUrls.employeeList.mask,
        element: <EmployeeList />,
      },
      {
        path: routerUrls.agencyProfile.mask,
        element: <AgencyProfile />,
      },
      {
        path: routerUrls.booking.mask,
        element: <BookingPage />,
      },
      {
        path: routerUrls.rateCard.mask,
        element: <RateCard />,
      },
      {
        index: true,
        element: <Login />,
      },
      {
        path: '*',
        element: <Navigate to={'/'} replace />,
      },
    ],
  },
]);

export default router;
