import * as React from 'react';

import { ILead, LeadResultModalState, LeadStatus as ELeadStatus, leadStatusEntities } from '@/entities/lead';
import { CreateLeadResultType } from '@/entities/modal';
import { Nullable } from '@/types/values';

import CreateLeadResultModal from '../CreateLeadResultModal';

import s from './LeadStatus.module.scss';

const LeadStatus: React.FC<{ item: ILead }> = ({ item }) => {
  const status = leadStatusEntities[item.status];

  const [resultModalState, setResultModalState] = React.useState<Nullable<LeadResultModalState>>(null);

  const openResultModal = React.useCallback(() => {
    setResultModalState({
      type: CreateLeadResultType.refusedWithInfoList,
      data: {
        crmRejectionInfo: item.crmRejectionInfo,
      },
    });
  }, [item]);

  const closeResultModal = React.useCallback(() => {
    setResultModalState(null);
  }, []);

  return (
    <>
      <CreateLeadResultModal state={resultModalState} onClose={closeResultModal} withBackdrop={false} />
      {status.label}
      {status.id == ELeadStatus.nonUnique && (
        <p className={s.status_notUnique} role="button" onClick={openResultModal}>
          Смотреть причину
        </p>
      )}
    </>
  );
};

export default LeadStatus;
