import { BaseLeadServer, IBaseLead } from '@/entities/lead';
import { formatDDMMYY } from '@/utils/formatDate';

export class BaseLeadModel implements IBaseLead {
  readonly id;
  readonly status;
  readonly placeType;
  readonly createdDate;
  readonly expiryDate;
  readonly project;
  readonly comment;

  constructor({ id, status, placeType, createdDate, expiryDate, project, comment }: IBaseLead) {
    this.id = id;
    this.status = status;
    this.placeType = placeType;
    this.createdDate = createdDate;
    this.expiryDate = expiryDate;
    this.project = project;
    this.comment = comment;
  }

  get createdDateFormatted(): string {
    return formatDDMMYY(this.createdDate);
  }

  get expiryDateFormatted(): string {
    return this.expiryDate ? formatDDMMYY(this.expiryDate) : '';
  }

  static baseLeadFromJson(raw: BaseLeadServer): BaseLeadModel {
    return new BaseLeadModel({
      id: raw.id,
      status: raw.status,
      placeType: raw.place_type,
      createdDate: raw.created_at,
      expiryDate: raw.hold_date,
      project: raw.project.name,
      comment: raw.comment,
    });
  }
}
