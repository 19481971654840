import cn from 'classnames';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import { TypoView } from '@/components/Typo';

import s from './TextButton.module.scss';

type TextButtonProps = {
  className?: string;
  children: React.ReactNode;
  underlined?: boolean;
  onClick?: VoidFunction;
  textView?: TypoView;
};

const TextButton: React.FC<TextButtonProps> = ({
  className,
  children,
  underlined = false,
  onClick,
  textView = TypoView.textMedium,
}) => {
  return (
    <Button
      className={cn(className, s.btn, underlined && s.btn_underlined)}
      view={ButtonType.ghost}
      textView={textView}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default TextButton;
